// esta es la pagina de gracias de contacto
import React from "react"
import Layout from "../components/layout"
import { Helmet } from "react-helmet"
import Hero from "../components/hero"
import imgHero from "../assets/images/bgHeroTrabaja.jpg"
import Block1 from "../components/trabaja/block"
import Formulario from "../components/formulario"
import img1 from "../assets/images/Trabaja01.jpg"
import img2 from "../assets/images/Trabaja01.jpg"
export default function trabajaNosotros() {
  return (
    <>
      <Helmet>
        <title>Trabaja con Nosotros</title>
        <meta name="description" content="Trabaja con Nosotros"></meta>
      </Helmet>
      <Layout>
        <Hero title="Trabaja con Nosotros" img={imgHero}></Hero>
        <Block1
          img1={img1}
          img2={img2}
          title1="PROP&Oacute;SITO"
          title2="LINEAMIENTO ESTRAT&Eacute;GICO"
        ></Block1>
        <Formulario
          title='¿QUIERES TRABAJAR CON NOSOTROS?'
          text='Registra tus datos si deseas participar de nuestro proyecto INTRAMET,
          nos estaremos poniendo en contacto'
          cv={true}
          >
        </Formulario>
      </Layout>
    </>
  )
}
