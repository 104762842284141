import React from "react"
import { useFormik } from "formik"
import * as Yup from "yup"
import Button from "../botonFormulario"
import { Wrapper } from "./style"

const Formulario = props => {
  const requiredText = "Es requerido este campo"
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      telefono: "",
      cv: "",
      mensaje: "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .min(3, "Tiene que tener al menos 3 letras")
        .required(requiredText),
      email: Yup.string()
        .email("Dirección de correo electrónico inválida")
        .required(requiredText),

      telefono: Yup.string()
        .required(requiredText)
        .matches(/^[0-9]+$/, "Tiene que ser solo digitos")
        .min(9, "Tiene que ser de 9 digitos el número de teléfono")
        .max(9, "Tiene que ser de 9 digitos el número de teléfono"),

      cv: Yup.string().when({
        is: props.cv,
        then: Yup.string(),
        otherwise: Yup.string().required(requiredText),
      }),
      mensaje: Yup.string().required(requiredText),
    }),
    onSubmit: values => {
      alert(JSON.stringify(values, null, 2))
    },
  })
  return (
    <div data-aos="fade-right" data-aos-duration="1500" className="container">
      <Wrapper>
        <div className="cabecera">
          <h2>{props.title || "undefined"}</h2>
          <p>{props.text || "undefined"}</p>
        </div>
        <form onSubmit={formik.handleSubmit}>
          <label htmlFor="name">Nombres y Apellidos </label>
          <input id="name" type="text" {...formik.getFieldProps("name")} />
          {formik.touched.name && formik.errors.name ? (
            <div className='errorForm'>{formik.errors.name}</div>
          ) : null}
          <label htmlFor="telefono">Tel&eacute;fono</label>
          <input
            id="telefono"
            type="text"
            {...formik.getFieldProps("telefono")}
          />
          {formik.touched.telefono && formik.errors.telefono ? (
            <div className='errorForm'>{formik.errors.telefono}</div>
          ) : null}
          <label htmlFor="email">Email</label>
          <input id="email" type="email" {...formik.getFieldProps("email")} />
          {formik.touched.email && formik.errors.email ? (
            <div className='errorForm'>{formik.errors.email}</div>
          ) : null}
          {props.cv ? (
            <>
              <label htmlFor="cv">Adjunta tu CV</label>
              <input
                id="cv"
                name="cv"
                type="file"
                {...formik.getFieldProps("cv")}
              />
              {formik.touched.cv && formik.errors.cv ? (
                <div className='errorForm'>{formik.errors.cv}</div>
              ) : null}
            </>
          ) : null}
          <label htmlFor="mensaje">Mensaje</label>
          <textarea
            name="mensaje"
            id="mensaje"
            cols="30"
            rows="10"
            {...formik.getFieldProps("mensaje")}
          ></textarea>
          {formik.touched.mensaje && formik.errors.mensaje ? (
            <div className='errorForm'>{formik.errors.mensaje}</div>
          ) : null}
          <div className="buttons">
            <Button>CORREO</Button>
          </div>
          {/* <button type="submit">Submit</button> */}
        </form>
      </Wrapper>
    </div>
  )
}
export default Formulario
